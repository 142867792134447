import { styled } from '@neui/core';
import { HStack, VStack } from '@neui/layout';
import {
  Headline,
  IconNames,
  IconThumbnail,
} from '@neui/styleguide-commerzbank';
import { ReactNode } from 'react';

type CdsContactItemProps = {
  headline: string;
  icon: IconNames;
  children: ReactNode;
};

export const CdsContactItem = ({
  headline,
  icon,
  children,
}: CdsContactItemProps) => {
  return (
    <HStack spacing={32}>
      <StyledIconThumbnail icon={{ icon }} />
      <VStack spacing={16}>
        <Headline type="h5">{headline}</Headline>
        {children}
      </VStack>
    </HStack>
  );
};

const StyledIconThumbnail = styled(IconThumbnail, {
  minWidth: 50,
});
