import { DomElement } from 'html-react-parser';
import { VStack } from '@neui/layout';
import { SelfDescribingJson } from '@snowplow/browser-tracker';

import {
  Accordion,
  AccordionComponentItemProps,
} from '@components/neui-components/molecules/Accordion';
import { useTracker } from '@utils/snowplowTracking';

import { ParserComponentBasicProps } from '../util';
import { isDomNode } from '../util';
import { RootRenderer } from '../RootRenderer/RootRenderer';

function extractLabelTitle(node?: DomElement): string | undefined {
  const textNode = node?.children?.find((child) => {
    return child.type === 'text' && (child.data as string).trim().length > 0;
  })?.data as string | undefined;

  return textNode?.trim();
}

export function isFoldout({ name, children }: DomElement) {
  return (
    name === 'div' &&
    children?.find(
      (el) => el.name === 'input' && el.attribs?.id.startsWith('foldout'),
    )
  );
}

export function SabioFoldoutRenderer({
  domNode,
  renderDomNode,
  context,
}: ParserComponentBasicProps & {
  context: SelfDescribingJson<Record<string, unknown>>;
}) {
  const childNodes = isDomNode(domNode) ? domNode.children ?? [] : domNode;
  const { trackButtonClick } = useTracker(SabioFoldoutRenderer.name);
  const handleValueChange = (value: string | null) => {
    if (value && value?.length > 1) {
      trackButtonClick?.(value, 'faq_accordion', [context]);
    }
  };

  const foldoutItems: AccordionComponentItemProps[] = childNodes
    ?.filter(isFoldout)
    ?.filter((child) => child.children !== undefined) // NOTE(benjamin): necessary here?
    .map((foldout) => {
      const labelNode = foldout.children?.find(({ name }) => name === 'label');
      const contentNode = foldout.children?.find(({ name }) => name === 'div');
      const accordionTitle = extractLabelTitle(labelNode);

      const result: AccordionComponentItemProps = {
        label: accordionTitle ?? '', // NOTE(benjamin): what if the title is empty?
        titleAs: 'h2',
        children: (
          <VStack spacing={24}>
            <RootRenderer
              domNode={contentNode?.children ?? []}
              renderDomNode={renderDomNode}
              headlineRank={3}
            />
          </VStack>
        ),
      };

      return result;
    });

  return (
    <Accordion
      type="single"
      collapsible
      items={foldoutItems}
      onValueChange={handleValueChange}
      style={{ maxWidth: 720 }}
    />
  );
}
