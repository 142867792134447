import React from 'react';
import { styled } from '@neui/core';

import { isDomNode, ParserComponentBasicProps } from '../util';
import { RootRenderer } from '../RootRenderer/RootRenderer';

export default function CdsULRenderer({
  domNode,
  renderDomNode,
}: ParserComponentBasicProps) {
  const childNodes = isDomNode(domNode) ? domNode.children ?? [] : [];

  if (childNodes.some(({ type, name }) => type === 'tag' && name === 'li')) {
    return (
      <StyledUl>
        <RootRenderer domNode={domNode} renderDomNode={renderDomNode} />
      </StyledUl>
    );
  }

  return null;
}

const StyledUl = styled('ul', {
  listStyle: 'none',
  counterReset: 'list-counter',
  display: 'table',
  borderCollapse: 'separate',
  borderSpacing: '0 16px',
  marginY: '-16px', // this corrects the external border-spacing
  maxWidth: 720,
  '& > li': {
    '&:before': {
      content: '–',
    },
  },
});
