import { AsProps, CSSProps, styled } from '@neui/core';
import { LabelProps as RadixLabelProps } from '@radix-ui/react-label';
import { forwardRef } from 'react';
import { Text, TextProps } from '@neui/styleguide-commerzbank';

export type LabelProps = AsProps &
  CSSProps &
  RadixLabelProps &
  Pick<TextProps, 'textAlign' | 'type'> & {};

const StyledText = styled(Text, {});

const StyledLabel = styled('label', {
  width: 'initial !important',
});

export const Label = forwardRef<HTMLLabelElement, LabelProps>(
  ({ children, textAlign = 'left', type = 'copy', ...rest }, forwardedRef) => {
    return (
      <StyledText
        renderAs={StyledLabel}
        ref={forwardedRef}
        textAlign={textAlign}
        type={type}
        {...rest}
      >
        {children}
      </StyledText>
    );
  },
);
Label.displayName = 'Label';
