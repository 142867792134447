import { DomElement, domToReact } from 'html-react-parser';

import { SectionSeparator } from '@components/SectionSeparator/SectionSeparator';

import CdsImgRenderer from '../ImgRenderer/CdsImgRenderer';
import CdsULRenderer from '../ULRenderer/CdsUlRenderer';
import CdsLIRenderer from '../LIRenderer/CdsLIRenderer';
import CdsOLRenderer from '../OLRenderer/CdsOLRenderer';
import HtmlTagIgnorer from '../HtmlTagIgnorer/HtmlTagIgnorer';
import CdsLinkRenderer from '../LinkRenderer/CdsLinkRenderer';
import CdsParagraphRenderer from '../ParagraphRenderer/CdsParagraphRenderer';
import { CdsMainRenderer } from '../CdsMainRenderer/CdsMainRenderer';
import { ParserComponentBasicProps } from '../util';
import { LinkRendererProps } from '../LinkRenderer/CdsLinkRenderer';
import { CdsHtmlParserProps } from '../CdsHtmlParser';
import { isDomNode } from '../util';

export type RenderersProps = CdsHtmlParserProps & LinkRendererProps;

export const renderDomNode =
  (rendererProps: RenderersProps) =>
  (domNode: DomElement, headlineRank: 2 | 3 = 2) => {
    const name = domNode.name || domNode.type;
    const defaultProps: ParserComponentBasicProps = {
      domNode,
      renderDomNode: renderDomNode(rendererProps),
      headlineRank,
    };
    let node;
    switch (name) {
      case 'main':
        node = (
          <CdsMainRenderer {...defaultProps} context={rendererProps.context} />
        );
        break;
      case 'p':
        node = <CdsParagraphRenderer {...defaultProps} />;
        break;
      case 'a':
        node = (
          <CdsLinkRenderer
            {...defaultProps}
            context={rendererProps.context}
            linksMetadata={rendererProps.linksMetadata}
          />
        );
        break;
      case 'img':
        node = (
          <CdsImgRenderer {...defaultProps} context={rendererProps.context} />
        );
        break;
      case 'ul':
        node = <CdsULRenderer {...defaultProps} />;
        break;
      case 'ol':
        node = <CdsOLRenderer {...defaultProps} />;
        break;
      case 'li':
        node = <CdsLIRenderer {...defaultProps} />;
        break;
      case 'hr':
        node = <SectionSeparator />;
        break;
      case 'text':
        node = domNode.data;
        break;
      default:
        node = <HtmlTagIgnorer {...defaultProps} />;
    }
    return node;
  };

export function RootRenderer({
  domNode,
  renderDomNode,
  headlineRank,
}: ParserComponentBasicProps) {
  const childNodes = isDomNode(domNode) ? domNode.children ?? [] : domNode;

  return (
    <>
      {childNodes &&
        domToReact(childNodes, {
          replace: (domNode) => renderDomNode(domNode, headlineRank),
        })}
    </>
  );
}
