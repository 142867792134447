import { Image } from '@components/Image';
import { useMakeLink } from '@components/Link';
import { IconLink } from '@components/neui-components/atoms/IconLink';
import { SnackbarToast } from '@components/neui-components/molecules/SnackbarToast';
import { AspectRatio, HStack, VStack } from '@neui/layout';
import {
  Headline,
  Input,
  interaction___close,
  interaction_arrows_arrowRight,
  Typography,
} from '@neui/styleguide-commerzbank';
import { baseTheme, oceanElevatedTheme } from 'styling/stitches.config';
import { useTranslation } from '@utils/i18n';
import { useEffect, useRef, useState } from 'react';

const MAX_CHARACTER = 200;
const MIN_CHARACTER = 3;

function isTextfieldValid(inputText: string): boolean {
  return inputText.length >= MIN_CHARACTER && inputText.length <= MAX_CHARACTER;
}

function containsPersonalData(
  nameList: Set<string>,
  text: string,
  cursorPos?: number,
): boolean {
  const words = dropWordAtPos(text, cursorPos)
    .split(/\s/)
    .map((w) => w.toLowerCase());

  const containsNames = words.some((word) => nameList.has(word));

  const containsNumbers = words.some((word) => word.match(/[0-9]/g) !== null);

  return containsNames || containsNumbers;
}

function dropWordAtPos(text: string, pos?: number): string {
  if (pos === undefined) {
    return text;
  }

  const before = text.slice(0, pos).replace(/[^\s]*$/, '');
  const after = text.slice(pos).replace(/^[^\s]*/, '');
  return `${before}${after}`;
}

export type FeedbackTextFieldProps = {
  label: string;
  onInputTextChange: (text: string) => void;
  onValidChange: (valid: boolean) => void;
  onShowContactsClick: () => void;
};

export const FeedbackTextField = ({
  label,
  onInputTextChange,
  onValidChange,
  onShowContactsClick,
}: FeedbackTextFieldProps): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputText, setInputText] = useState<string>('');
  const [cursorPos, setCursorPos] = useState<number | undefined>();
  const [nameList, setNameList] = useState<Set<string>>(new Set());
  const [hasPersonalData, setHasPersonalData] = useState(false);
  const [showPersonalDataWarning, setShowPersonalDataWarning] = useState(false);

  const { $t } = useTranslation();
  const makeLink = useMakeLink();

  useEffect(() => {
    const blacklistUrl =
      makeLink({
        href: '/names.json',
        alwaysPrependBasePath: true,
      }) ?? '';
    fetch(blacklistUrl)
      .then((r) => r.json())
      .then((names) => setNameList(new Set(names)));
  }, [makeLink]);

  useEffect(() => {
    const input = inputRef.current;
    if (input !== null) {
      const updateCursorPos = () =>
        setCursorPos(input.selectionStart ?? undefined);
      const unsetCursorPosition = () => setCursorPos(undefined);

      input.addEventListener('keyup', updateCursorPos);
      input.addEventListener('click', updateCursorPos);
      input.addEventListener('focus', updateCursorPos);
      input.addEventListener('blur', unsetCursorPosition);

      return () => {
        input.removeEventListener('keyup', updateCursorPos);
        input.removeEventListener('click', updateCursorPos);
        input.removeEventListener('focus', updateCursorPos);
        input.removeEventListener('blur', unsetCursorPosition);
      };
    }
  });

  useEffect(() => {
    const timeout = setTimeout(
      () =>
        setHasPersonalData(
          containsPersonalData(nameList, inputText, cursorPos),
        ),
      100,
    );

    return () => clearTimeout(timeout);
  }, [nameList, inputText, cursorPos]);

  useEffect(() => {
    setShowPersonalDataWarning(hasPersonalData);
  }, [hasPersonalData]);

  const remainingChars = MAX_CHARACTER - inputText.length;

  return (
    <>
      <Input
        ref={inputRef}
        value={inputText}
        maxLength={MAX_CHARACTER}
        invalid={inputText.length > 0 && !isTextfieldValid(inputText)}
        label={label}
        helperText={
          isTextfieldValid(inputText)
            ? `${$t('FEEDBACK_ANSWER4_HELPERTEXT')}${remainingChars}`
            : $t('FEEDBACK_INPUT_ERROR')
        }
        placeholder={$t('FEEDBACK_ANSWER4_PLACEHOLDER')}
        data-cy={'feedback-no-input'}
        onChange={({ target: { value } }) => {
          setInputText(value);
          onInputTextChange(value);
          onValidChange(isTextfieldValid(value));
        }}
      />

      <SnackbarToast
        position={'right'}
        className={`${baseTheme} ${oceanElevatedTheme}`}
        open={showPersonalDataWarning}
      >
        <VStack alignItems="end">
          <HStack spacing={16} alignItems="start">
            <AspectRatio aspectRatio="1" size={96}>
              <Image src="/icons/snackbar-happy.svg" alt="" />
            </AspectRatio>
            <HStack spacing={64}>
              <VStack alignItems="start">
                <Headline type="h5">Möchten Sie Kontakt aufnehmen?</Headline>
                <Typography size={7}>
                  An dieser Stelle können wir Ihre persönlichen Daten leider
                  nicht bearbeiten. Wenn Sie ein persönliches Anliegen haben,
                  nutzen Sie einen unserer Kontaktkanäle. Dort helfen wir Ihnen
                  gerne weiter.
                </Typography>
              </VStack>
            </HStack>
            <HStack alignItems="right"></HStack>
          </HStack>
          <HStack spacing={32}>
            <IconLink
              icon={interaction___close}
              onClick={() => setShowPersonalDataWarning(false)}
              animationDirection="top"
            >
              Schließen
            </IconLink>
            <IconLink
              icon={interaction_arrows_arrowRight}
              onClick={() => {
                setShowPersonalDataWarning(false);
                onShowContactsClick();
              }}
              animationDirection="top"
            >
              Kontaktkanäle
            </IconLink>
          </HStack>
        </VStack>
      </SnackbarToast>
    </>
  );
};
