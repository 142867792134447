import { DomElement } from 'html-react-parser';

export type ParserComponentBasicProps = {
  domNode: DomElement | DomElement[];
  renderDomNode: (el: DomElement, headlineRank?: 2 | 3) => any;
  headlineRank?: 2 | 3;
};

export function isDomNode(
  domNode: DomElement | DomElement[],
): domNode is DomElement {
  return !Array.isArray(domNode);
}
