import { Layer } from '@components/neui-components/molecules/Layer';
import { VStack } from '@neui/layout';
import { Headline, Text, Typography } from '@neui/styleguide-commerzbank';
import { useTranslation } from '@utils/i18n';
import { useTracker } from '@utils/snowplowTracking';
import { useRuntimeSettings } from '@utils/config';
import { SelfDescribingJson } from '@snowplow/browser-tracker';
import { FunctionComponent } from 'react';

import { CdsContactItem } from './CdsContactItem';
import { TrackedLink } from './CdsContacts';

export type ContactsLayerProps = {
  isOpen: boolean;
  close: () => void;
  trackLinkClick: ReturnType<typeof useTracker>['trackLinkClick'];
  portalContext: SelfDescribingJson;
};

export const ContactsLayer: FunctionComponent<ContactsLayerProps> = ({
  isOpen,
  close,
  trackLinkClick,
  portalContext,
}: ContactsLayerProps) => {
  const { $t } = useTranslation();
  const {
    staticContent: { links },
  } = useRuntimeSettings();

  return (
    <Layer
      closeLabel={$t('BACK_TO_OVERVIEW')}
      open={isOpen}
      onOpenChange={() => {
        trackLinkClick?.($t('BACK_TO_OVERVIEW'), 'contact', [''], '', [
          portalContext,
        ]);
        close();
      }}
    >
      <VStack spacing={64}>
        <VStack spacing={24}>
          <Headline type="h3">{$t('CONTACT_QUESTIONS')}</Headline>
          <Text>{$t(`CONTACT_HELP_GPP`)}</Text>
        </VStack>
        <CdsContactItem
          headline={$t('CONTACT_FORM')}
          icon={'communication___envelope'}
        >
          <Text>{$t('CONTACT_FORM_HINT_GPP')}</Text>
          <Text>
            <TrackedLink
              href={links.contact}
              label={$t('CONTACT_FORM')}
              location="contact"
              portalContext={portalContext}
              rel="noopener noreferrer"
              underlined
            />
          </Text>
        </CdsContactItem>
        <CdsContactItem
          headline={$t('CONTACT_PHONE')}
          icon="communication___call"
        >
          <Text>{$t('CONTACT_PHONE_PIN_GPP')}</Text>

          <Text>
            <Typography weight={'bold'}>{$t('CONTACT_LABEL_GPP')}:</Typography>
            &nbsp;
            <TrackedLink
              href={$t('CONTACT_PHONE_GPP_URL')}
              title={'FK-Tel'}
              label={$t('CONTACT_PHONE_GPP_PRETTY')}
              location="contact"
              portalContext={portalContext}
              target="_blank"
            />
          </Text>
          <Text>{$t('CONTACT_HOTLINES_TEXT')}</Text>
          <Text>
            <TrackedLink
              href={$t('CONTACT_HOTLINES_LINK')}
              label={$t('CONTACT_HOTLINES_LABEL')}
              location="contact"
              portalContext={portalContext}
            />
          </Text>
        </CdsContactItem>
      </VStack>
    </Layer>
  );
};
