import { forwardRef, ReactNode, useState } from 'react';
import { Root, Indicator } from '@radix-ui/react-checkbox';
import { AsProps, CSSProps, styled } from '@neui/core';
import { HStack, VStack } from '@neui/layout';
import { interaction___dashShort } from '@neui/styleguide-commerzbank';

import { Icon } from './Icon';
import { HelperText } from './HelperText';
import { Label } from './Label';
import { useComponentId } from '../utils/id';

import type {
  CheckboxProps as RadixCheckboxProps,
  CheckedState,
} from '@radix-ui/react-checkbox';

export type CheckboxProps = AsProps &
  CSSProps &
  RadixCheckboxProps & {
    label?: string | ReactNode;
    helperText?: string | ReactNode;
    invalid?: boolean;
    theme?: 'sand' | 'ocean';
  };

const Wrapper = styled(HStack, {});
const StyledLabel = styled(Label, {
  marginLeft: 'calc($component-3 * -1)',
  paddingLeft: '$component-3',
  cursor: 'pointer',
});

const LabelWrapper = styled(VStack, {});

const StyledIcon = styled(Icon, {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '& > svg': {
    size: 16,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  variants: {
    theme: {
      ocean: {
        '& > svg > *': {
          fill: '$petrol700',
        },
      },
      sand: {
        '& > svg > *': {
          fill: 'white',
        },
      },
    },
  },
});

const StyledHelperText = styled(HelperText, {
  variants: {
    theme: {
      ocean: {
        color: '#92A4AB',
      },
      sand: {
        color: '#506C74',
      },
    },
    invalid: {
      true: {
        color: '$error !important',
      },
    },
  },
});

const StyledRoot = styled(Root, {
  all: 'unset',
  boxShadow: 'inset 0px 0px 0px 2px',
  size: 24,
  borderRadius: 4,
  position: 'relative',
  zIndex: 0,
  transition: 'box-shadow 0.1s ease-in-out',
  variants: {
    theme: {
      ocean: {},
      sand: {},
    },
    disabled: {
      true: {
        backgroundColor: 'none',
      },
      false: {
        cursor: 'pointer',
        color: '#506C74',
        '&:focus-visible': {
          outline: '2px solid $text-standard',
          outlineOffset: 4,
        },
        '&[data-state="checked"], &[data-state="indeterminate"], &[data-state="checked"]:hover':
          {
            boxShadow: 'inset 0px 0px 0px 20px',
          },
        '&[data-state="checked"]:hover': {
          '& > span > svg > path': {
            strokeWidth: '3px',
            transition: '0.1s ease-in-out',
          },
        },
        '&:hover:not([data-state="checked"], [data-state="indeterminate"])': {
          boxShadow: 'inset 0px 0px 0px 4px',
        },
        '&:hover:not([data-state="indeterminate"])': {
          borderRadius: 5,
        },
      },
    },
    invalid: {
      true: {
        color: '$error !important',
      },
    },
  },
  compoundVariants: [
    {
      theme: 'ocean',
      disabled: true,
      css: {
        color: '#426671',
        '&[data-state="checked"], &[data-state="indeterminate"]': {
          backgroundColor: '#426671',
        },
        [`& ~ ${LabelWrapper} ${StyledLabel}`]: {
          color: '#426671',
        },
      },
    },
    {
      theme: 'sand',
      disabled: true,
      css: {
        color: '$text-disabled',
        '&[data-state="checked"], &[data-state="indeterminate"]': {
          backgroundColor: '$text-disabled',
        },
        [`& ~ ${LabelWrapper} ${StyledLabel}`]: {
          color: '$text-disabled',
        },
      },
    },
    {
      theme: 'ocean',
      disabled: false,
      invalid: false,
      css: {
        color: '#92A4AB',
        '&[data-state="checked"], &[data-state="indeterminate"], &[data-state="checked"]:hover':
          {
            color: '$primary',
            '& > span > svg > path': {
              stroke: '$petrol700',
            },
          },
        '&:hover:not([data-state="checked"], [data-state="indeterminate"])': {
          color: '#B7C4C9',
        },
      },
    },
    {
      theme: 'sand',
      disabled: false,
      css: {
        color: '#506C74',
        '&[data-state="checked"], &[data-state="indeterminate"], &[data-state="checked"]:hover':
          {
            color: '$petrol700',
          },
        '&:hover:not([data-state="checked"], [data-state="indeterminate"])': {
          color: '$petrol600',
        },
      },
    },
  ],
});

const StyledIndicator = styled(Indicator, {
  position: 'absolute',
  inset: 0,
  zIndex: 1,
});

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  (
    {
      helperText,
      label,
      checked,
      invalid = false,
      disabled = false,
      theme = 'sand',
      id: otherIds,
      onCheckedChange,
      ...rest
    },
    forwardedRef,
  ) => {
    const id = useComponentId('checkbox', otherIds);
    const labelId = useComponentId('checkbox-label');
    const [isChecked, setIsChecked] = useState<CheckedState>(checked || false);

    function getIndicatorIcon(checked: CheckedState) {
      switch (checked) {
        case true:
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M6 12.5L10 16.5L18 8.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="square"
              />
            </svg>
          );
        case false:
          return null;
        case 'indeterminate':
          return (
            <StyledIcon
              icon={interaction___dashShort}
              size="small"
              variant="solid"
              theme={theme}
            />
          );
        default:
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M6 12.5L10 16.5L18 8.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="square"
              />
            </svg>
          );
      }
    }

    const handleInteraction = () => {
      if (disabled) {
        return;
      }
      setIsChecked(!isChecked);
      onCheckedChange?.(!isChecked);
    };

    return (
      <Wrapper alignItems="flex-start" spacing="$component-3">
        <StyledRoot
          {...rest}
          ref={forwardedRef}
          id={id}
          checked={isChecked}
          aria-labelledby={labelId}
          data-invalid={invalid}
          invalid={invalid}
          disabled={disabled}
          onClick={handleInteraction}
          theme={theme}
          tabIndex={!disabled ? 0 : -1}
        >
          <StyledIndicator data-invalid={invalid}>
            {getIndicatorIcon(checked as CheckedState)}
          </StyledIndicator>
        </StyledRoot>
        <LabelWrapper>
          {label && (
            <StyledLabel id={labelId} htmlFor={id}>
              {label}
            </StyledLabel>
          )}
          {helperText && (
            <StyledHelperText textAlign="left" invalid={invalid} theme={theme}>
              {helperText}
            </StyledHelperText>
          )}
        </LabelWrapper>
      </Wrapper>
    );
  },
);
Checkbox.displayName = 'Checkbox';
