import React from 'react';

/**
 * NOTE(benjamin): Due to some issue in the LSG IconLink component's onClick method,
 * we need to enforce a one-time rerender of the component.
 */
export function useForceRerender() {
  const [rerendered, setRerendered] = React.useState(false);
  React.useEffect(() => setRerendered(true), [rerendered]);
}
