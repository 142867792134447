import { HStack } from '@neui/layout';
import { SelfDescribingJson } from '@snowplow/browser-tracker';
import { useState } from 'react';
import {
  communication_feedback_like,
  communication_feedback_dislike,
  Typography,
} from '@neui/styleguide-commerzbank';
import { styled } from '@neui/core';

import { useTranslation } from '@utils/i18n';
import { Step, useTracker } from '@utils/snowplowTracking';
import { IconLink } from '@components/neui-components/atoms/IconLink';
import { useRuntimeSettings } from '@utils/config';
import { GA4TrackFeedbackButtonClick } from '@utils/tracking';

export type CdsVideoBasicFeedbackProps = {
  context: SelfDescribingJson<Record<string, unknown>>;
  setFeedbackOpen: (b: boolean) => void;
  setHasReviewed: (b: boolean) => void;
  hasReviewed: boolean;
  setDisplaySnackbar: (b: boolean) => void;
};

type PossibleFeedbacks = null | 'yes' | 'no';

export function CdsVideoBasicFeedback({
  context,
  setFeedbackOpen,
  setHasReviewed,
  hasReviewed,
  setDisplaySnackbar,
}: CdsVideoBasicFeedbackProps) {
  const {
    tracking: {
      ga4: { enabled: enableGA4Tracking },
    },
  } = useRuntimeSettings();
  const { $t } = useTranslation();
  const { trackUserFeedback } = useTracker(CdsVideoBasicFeedback.name);

  const [feedbackAnswer, setFeedbackAnswer] = useState<PossibleFeedbacks>(null);
  return (
    <HStack
      alignItems={'center'}
      css={{ width: '100%', height: '100%' }}
      spacing={12}
    >
      <StyledTypography size={6} weight="medium" disabled={hasReviewed}>
        {$t('VIDEO_SUBLINE_QUESTION')}
      </StyledTypography>
      <IconLink
        variant={feedbackAnswer === 'yes' ? 'solid' : 'outline'}
        animationDirection="top"
        onClick={() => {
          setFeedbackAnswer('yes');
          trackUserFeedback?.(Step.WasVideoHelpful, ['Ja'], '', [context]);
          enableGA4Tracking &&
            GA4TrackFeedbackButtonClick(
              'Video',
              $t('VIDEO_SUBLINE_QUESTION'),
              'Ja',
            );
          setHasReviewed(true);
          setDisplaySnackbar(true);
        }}
        icon={communication_feedback_like}
        disabled={hasReviewed}
      ></IconLink>
      <IconLink
        variant={feedbackAnswer === 'no' ? 'solid' : 'outline'}
        animationDirection="top"
        onClick={() => {
          setFeedbackAnswer('no');
          trackUserFeedback?.(Step.WasVideoHelpful, ['Nein'], '', [context]);
          enableGA4Tracking &&
            GA4TrackFeedbackButtonClick(
              'Video',
              $t('VIDEO_SUBLINE_QUESTION'),
              'Nein',
            );
          setFeedbackOpen(true);
        }}
        icon={communication_feedback_dislike}
        disabled={hasReviewed}
      ></IconLink>
    </HStack>
  );
}

const StyledTypography = styled(Typography, {
  variants: {
    disabled: {
      true: {
        color: '$colors$text-disabled',
      },
      false: {},
    },
  },
});
