import React from 'react';
import { Headline } from '@neui/styleguide-commerzbank';
import { VStack } from '@neui/layout';

import { ParagraphMaxWidth } from '@components/CdsStyledComponents';

import { ParserComponentBasicProps } from '../util';
import { isDomNode } from '../util';
import { RootRenderer } from '../RootRenderer/RootRenderer';

export default function CdsParagraphRenderer({
  domNode,
  renderDomNode,
  headlineRank = 2,
}: ParserComponentBasicProps) {
  if (!isDomNode(domNode)) {
    return <></>;
  }

  const childNodes = domNode.children ?? [];

  if (
    domNode.attribs &&
    domNode.attribs.class &&
    domNode.attribs.class === 'ueberschriftImText'
  ) {
    return (
      <>
        {childNodes && !childNodes[0].name && (
          <VStack spacing={'$space$component-4'} /> // TODO: fix this later
        )}
        <Headline type={'h4'} renderAs={`h${headlineRank}`}>
          <RootRenderer
            domNode={childNodes}
            renderDomNode={renderDomNode}
            headlineRank={headlineRank}
          />
        </Headline>
      </>
    );
  }

  // if direct child is a link wrapping an image, don't wrap it
  if (
    childNodes.length > 0 &&
    childNodes[0].name === 'a' &&
    childNodes[0].children &&
    childNodes[0].children[0]?.name === 'img'
  ) {
    return (
      <RootRenderer
        domNode={childNodes}
        renderDomNode={renderDomNode}
        headlineRank={headlineRank}
      />
    );
  }

  return (
    <ParagraphMaxWidth>
      <RootRenderer
        domNode={childNodes}
        renderDomNode={renderDomNode}
        headlineRank={headlineRank}
      />
    </ParagraphMaxWidth>
  );
}
