import React from 'react';
import { Typography } from '@neui/styleguide-commerzbank';
import { styled } from '@neui/core';

import { ParserComponentBasicProps } from '../util';
import { RootRenderer } from '../RootRenderer/RootRenderer';

export default function CdsLIRenderer({
  domNode,
  renderDomNode,
}: ParserComponentBasicProps) {
  return (
    <StyledListItem renderAs={'li'}>
      <RootRenderer domNode={domNode} renderDomNode={renderDomNode} />
    </StyledListItem>
  );
}

const TypographySize5Styles = {
  fontFamily: '$sans',
  fontSize: '$mobile-5',
  lineHeight: '$mobile-5',
  letterSpacing: '$mobile-5',
  '@md': {
    fontSize: '$desktop-m1-5',
    lineHeight: '$desktop-m1-5',
    letterSpacing: '$desktop-m1-5',
  },
};

const StyledListItem = styled(Typography, {
  display: 'table-row',
  '&:before': {
    display: 'table-cell',
    width: 24,
    ...TypographySize5Styles,
  },
});
