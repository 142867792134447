import {
  ServerSideConf,
  ImageFormat,
  FetchImage,
  CreateFileName,
  formatToExtension,
  extensionToFormat,
  fetchUrl,
} from 'sidepix';
import { Readable } from 'stream';

const fetch: FetchImage = (src) => {
  if (typeof window === 'undefined') {
    return fetchUrl(
      `https://cdswissen.pcs.sabio.de/pcs/services/images/b49c42b66ada88c7016adf594159049d/2b90bffb432d43daa62fcbed0339c1f4/Serviceportal/${src}`,
    );
  }
  return (() => {}) as unknown as Readable;
};

export const pictureConf: ServerSideConf = {
  assetsBaseUrl: `${process.env.NEXT_PUBLIC_SP_BASEPATH ?? ''}/media`,
  serverSideProcessor: {
    fetch: fetch,
    originalDir: 'image-cache',
    processedDir: 'public/media',
  },
  targetFormats: (format) => {
    switch (format) {
      case ImageFormat.JPEG:
        return [ImageFormat.WEBP, ImageFormat.JPEG];
      case ImageFormat.PNG:
        return [ImageFormat.WEBP, ImageFormat.PNG];
      case ImageFormat.GIF:
        return [ImageFormat.WEBP];
      default:
        return [format];
    }
  },
  fallbackFormat: (format) => {
    switch (format) {
      case ImageFormat.GIF:
        return ImageFormat.JPEG;
      default:
        return format;
    }
  },
  logger: console,
};

export const createFileName: CreateFileName = ({ src, width }) => {
  const [base, ext] = src.split('.');
  const extension = formatToExtension(extensionToFormat(`.${ext}`));
  return base + (width !== undefined ? '_' + width : '') + extension;
};

// export const imageConf: ServerSideConf = {
//   assetsBaseUrl: '/media',
//   createFileName,
//   serverSideProcessor: {
//     fetch,
//     originalDir: 'image-cache',
//     processedDir: 'public/media',
//   },
//   logger: console,
// };
