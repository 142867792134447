import { styled } from '@neui/core';
import { Text, TextProps } from '@neui/styleguide-commerzbank';
import { forwardRef, HTMLAttributes } from 'react';

const StyledText = styled(Text, {
  variants: {
    invalid: {
      true: {
        color: '$error',
      },
    },
  },
});

export type HelperTextProps = HTMLAttributes<HTMLDivElement> &
  TextProps & {
    invalid?: boolean;
  };

export const HelperText = forwardRef<HTMLDivElement, HelperTextProps>(
  ({ invalid, children, ...rest }, forwardedRef) => {
    return (
      <StyledText {...rest} ref={forwardedRef} type="helper" invalid={invalid}>
        {children}
      </StyledText>
    );
  },
);

HelperText.displayName = 'HelperText';
