import React from 'react';
import {
  Headline,
  Text,
  Typography,
  people___callcenter,
} from '@neui/styleguide-commerzbank';
import { SelfDescribingJson } from '@snowplow/browser-tracker';
import { VStack } from '@neui/layout';

import { createPortalEntity, useTracker } from '@utils/snowplowTracking';
import { useForceRerender } from '@hooks/useForceRerender';
import { useTranslation } from '@utils/i18n';
import { useRuntimeSettings } from '@utils/config';
import { Layer } from '@components/neui-components/molecules/Layer';
import { IconLink } from '@components/neui-components/atoms/IconLink';
import { Link, LinkProps } from '@components/Link';
import {
  selectChatActions,
  selectChatProps,
  useChatStore,
} from '@components/Chat/store/store';
import { GA4TrackLinkClick, GA4TrackSideLayerOpen } from '@utils/tracking';

import { CdsContactItem } from './CdsContactItem';

interface ContactsProps {
  articleName: string;
  category: string;
  className: string;
}

interface TrackedLinkProps extends LinkProps {
  href: string;
  label?: string;
  title?: string;
  location: string;
  portalContext: SelfDescribingJson;
}

export function TrackedLink({
  href,
  label,
  title,
  location,
  portalContext,
}: TrackedLinkProps) {
  const { trackLinkClick } = useTracker(TrackedLink.name);
  const {
    tracking: {
      ga4: { enabled: enableGA4Tracking },
    },
  } = useRuntimeSettings();

  return (
    <Link
      href={href}
      underlined
      onClick={() => {
        trackLinkClick?.(href, label, [], location, [portalContext]);
        enableGA4Tracking &&
          GA4TrackLinkClick(
            href,
            title ?? label ?? '',
            location + ' Layer',
            'Sidelayer',
          );
      }}
    >
      {label}
    </Link>
  );
}

export const CdsContacts = ({
  articleName,
  category,
  className,
}: ContactsProps): React.ReactElement => {
  const {
    staticContent: { links },
  } = useRuntimeSettings();
  const { $t } = useTranslation();

  const { trackLinkClick } = useTracker(CdsContacts.name);

  const portalContext = createPortalEntity(category, articleName);
  useForceRerender();
  const [isOpen, openLayer] = React.useState(false);

  const closeLabel = $t('BACK_TO_OVERVIEW');

  const { setIsChatOpen } = useChatStore(selectChatActions);
  const { chatState } = useChatStore(selectChatProps);

  return (
    <>
      <VStack className={className} spacing={24}>
        <Typography>{$t('CONTACT_HINT')}</Typography>
        <IconLink
          icon={people___callcenter}
          iconPosition="left"
          animationDirection="right"
          css={{ width: 'fit-content' }}
          onClick={() => {
            trackLinkClick?.($t('CONTACT_US'), 'contact', [], '', [
              portalContext,
            ]);
            openLayer(true);
            GA4TrackSideLayerOpen('Contact Layer');
          }}
        >
          {$t('CONTACT_US')}
        </IconLink>
      </VStack>

      <Layer
        closeLabel={closeLabel}
        closeLabelId={closeLabel}
        open={isOpen}
        onOpenChange={() => {
          trackLinkClick?.($t('BACK_TO_OVERVIEW'), 'Contact', [''], '', [
            portalContext,
          ]);
          openLayer(false);
        }}
      >
        <VStack spacing={64}>
          <VStack spacing={24}>
            <Headline type="h3">{$t('CONTACT_QUESTIONS')}</Headline>
            <Text>{$t(`CONTACT_HELP`)}</Text>
          </VStack>
          {chatState.workgroupName && (
            <CdsContactItem headline={'Chat'} icon="communication___textchat">
              <Text>
                Persönlich und rund um die Uhr. Im authentifizierten
                Kundencenter können wir nicht nur Ihre Fragen beantworten,
                sondern auch viele weitere Leistungen anbieten. Vom Girokonto
                bis zur neuen Kreditkarte.
              </Text>
              <Link
                underlined
                onClick={() => {
                  trackLinkClick?.('Chat started', 'Contact', [], '', [
                    portalContext,
                  ]);
                  openLayer(false);
                  setIsChatOpen(true);
                }}
              >
                Chat beginnen
              </Link>
            </CdsContactItem>
          )}
          <CdsContactItem
            headline={$t('CONTACT_CALL_FROM_BANKING_APP')}
            icon={'communication___call'}
          >
            <Text>{$t('CONTACT_CALL_FROM_BANKING_APP_HINT')}</Text>
          </CdsContactItem>
          <CdsContactItem
            headline={$t('CONTACT_PHONE')}
            icon="communication___call"
          >
            <Text>{$t('CONTACT_PHONE_PIN')}</Text>
            <Text>
              <Typography weight={'bold'}>{$t('CONTACT_PUK')}:</Typography>
              &nbsp;
              <TrackedLink
                href={$t('FEEDBACK_CONTACT_HREF_PUK')}
                label={`(${$t('FEEDBACK_CONTACT_LABEL_PUK')})`}
                title={'PK-Tel'}
                underlined
                location="Contact"
                portalContext={portalContext}
              />
            </Text>
            <Text>
              <Typography weight={'bold'}>{$t('CONTACT_GPP')}:</Typography>
              &nbsp;
              <TrackedLink
                href={$t('FEEDBACK_CONTACT_HREF_GPP')}
                label={`(${$t('FEEDBACK_CONTACT_LABEL_GPP')})`}
                title={'UK-Tel'}
                underlined
                location="Contact"
                portalContext={portalContext}
              />{' '}
            </Text>
          </CdsContactItem>
          <CdsContactItem
            headline={$t('CONTACT_BRANCH')}
            icon={'location___house'}
          >
            <Text>
              {$t('CONTACT_BRANCH_FINDER_1')}{' '}
              <TrackedLink
                href="https://filialsuche.commerzbank.de"
                label={$t('CONTACT_BRANCH_FINDER_2')}
                title={$t('CONTACT_BRANCH_FINDER_2')}
                location="Contact"
                portalContext={portalContext}
                rel="noopener noreferrer"
              />{' '}
              {$t('CONTACT_BRANCH_FINDER_3')}
            </Text>
          </CdsContactItem>
          {!chatState.workgroupName && (
            <CdsContactItem
              headline={$t('CONTACT_FORM')}
              icon={'communication___envelope'}
            >
              <Text>{$t('CONTACT_FORM_HINT')}</Text>
              <Text>
                <TrackedLink
                  href={links.contact}
                  label={$t('CONTACT_FORM')}
                  title={$t('CONTACT_FORM')}
                  location="Contact"
                  portalContext={portalContext}
                  rel="noopener noreferrer"
                  underlined
                />
              </Text>
            </CdsContactItem>
          )}
        </VStack>
      </Layer>
    </>
  );
};
