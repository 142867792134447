import { DomElement } from 'html-react-parser';
import { SelfDescribingJson } from '@snowplow/browser-tracker';

import { ParserComponentBasicProps } from '../util';
import { RootRenderer } from '../RootRenderer/RootRenderer';
import {
  SabioFoldoutRenderer,
  isFoldout,
} from '../SabioFoldoutRenderer/SabioFoldoutRenderer';
import { isDomNode } from '../util';

function groupFoldouts(nodes: DomElement[]): {
  type: 'foldout' | 'other';
  nodes: DomElement[];
}[] {
  return nodes.reduce(
    (
      groups: {
        type: 'foldout' | 'other';
        nodes: DomElement[];
      }[],
      node,
    ) => {
      const currGroup = groups[groups.length - 1];
      const currType = currGroup?.type;
      const nodeType = isFoldout(node) ? 'foldout' : 'other';
      if (nodeType === currType) {
        currGroup.nodes.push(node);
        return groups;
      } else {
        return [...groups, { type: nodeType, nodes: [node] }];
      }
    },
    [],
  );
}

export function CdsMainRenderer({
  domNode,
  renderDomNode,
  context,
}: ParserComponentBasicProps & {
  context: SelfDescribingJson<Record<string, unknown>>;
}) {
  if (!isDomNode(domNode)) {
    return <></>;
  }

  return (
    <>
      {groupFoldouts(domNode.children ?? []).map(({ type, nodes }, key) =>
        type === 'foldout' ? (
          <SabioFoldoutRenderer
            domNode={nodes}
            renderDomNode={renderDomNode}
            key={key}
            context={context}
          />
        ) : (
          <RootRenderer
            domNode={nodes}
            renderDomNode={renderDomNode}
            key={key}
          />
        ),
      )}
    </>
  );
}
