import React from 'react';

import { ParserComponentBasicProps } from '../util';
import { RootRenderer } from '../RootRenderer/RootRenderer';

export default function HtmlTagIgnorer({
  domNode,
  renderDomNode,
}: ParserComponentBasicProps) {
  return (
    <React.Fragment>
      <RootRenderer domNode={domNode} renderDomNode={renderDomNode} />
    </React.Fragment>
  );
}
